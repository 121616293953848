.header {
    width: 100%;
    height: 70px;
    background: linear-gradient(90deg, rgba(16, 209, 163, 1) 0%, rgb(18, 195, 218) 100%);
    display: flex;
    position: fixed;
    z-index: 10;
}

.header-logo {
    padding: 12px;
    padding-left: 30px;
    width: 80px;
    // filter: invert(21%) sepia(23%) saturate(3733%) hue-rotate(173deg) brightness(102%) contrast(98%);
}

.header-container-left {
    width: 100%;
    text-align: right;
    align-self: center;
}

.header-button {
    padding: 25px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    color: #035672;
    font-weight: bold;
    white-space: nowrap;
    
    @media (max-width: 600px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.header-link {
    cursor: pointer;
    text-decoration: none;
    color: #035672;
}

.header-options {
    display: flex;
    justify-content: right;
    margin-right: 20px;
}

.header-picture-container {
    background-color: #8edfdf;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    align-self: center;
    display: flex;
    align-items: center;
    place-content: center;
    cursor: pointer;
}

.header-picture-default{
    font-size: 20px;
    opacity: 0.4;
}

.header-options-picture-default{
    font-size: 50px;
    opacity: 0.4;
}

.header-user-container {
    display: flex;
}

.header-user-options-container {
    position: absolute;
}

.header-user-options {
    position: absolute;
    background-color: white;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    right: 0;
    margin-top: 65px;
    border-radius: 10px;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    border: 1px solid #ccc!important;
    transform: translate(50px, -0%);
    min-width: 200px;
}

.header-user-options-background {
    width: 100%;
    height: 100%;
    right: 0vw;
    position: fixed;
    top: 0vw;
    opacity: .5;
}

.header-user-options-main-info {
    text-align: center;
    cursor: pointer;

    &:hover{
        .color-blue {
            color: #01202b;
        }
    }
}

.header-user-options-picture-container {
    width: 100px;
    height: 100px;
    padding: 25px;
    padding-bottom: 0px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-user-options-name {
    font-size: 32px;
}

.header-user-option {
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    width: 100%;

    &:hover{
        color: #01202b;;
    }
}

.header-user-options-list {
    text-align: left;
    border-top: 1px solid rgba(0, 0, 0, .5);
    padding-top: 5px;
}