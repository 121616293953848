.notfound-container {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.notfound-title {
    align-self: center;
}