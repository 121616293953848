.catalogcard-container {
   display: flex;
}

.catalogcard {
    background-color: #10887b42;
    border-radius: 20px;
    padding: 25px;
    margin: 25px;
    color: #83918f;
    display: flex;
    max-width: 300px;
    text-decoration: none;
    cursor: pointer;
    width: 100%;

    @media (max-width: 900px) {
        display: block;
        padding: 15px;
        margin: 15px;
    }

    @media (max-width: 450px) {
      max-width: 130px;
      margin: 5px;
  }

}
   
.catalogcard-create {
   background-color: #cac8c842;
   color: #9d9c9c;
   display: flex;
   align-items: center;
   place-content: center;
   font-size: 28px;
   border-radius: 20px;
   border: 2px solid #d3cece;
   border-style: dashed;

   @media (max-width: 600px) {
      font-size: 18px;
   }
}

 .catalogcard-info {
    text-align: left;
    margin-left: 15px;
    width: 100%;

    @media (max-width: 500px) {
      margin-left: 0px;
   }
 }

 .catalogcard-lock {
   margin-bottom: -3px;
 }

 .catalogcard-image {
    width: 100px;
    height: 100px;

    @media (max-width: 900px) {
        width: 50px;
        height: 50px;
    }
 }

 .catalogcard-description {
   font-size: 20px;
   margin-top: 5px;
   margin-bottom: 5px;

   @media (max-width: 600px) {
      font-size: 14px;
  }
 }

 .catalogcard-owner {
   font-size: 14px;

   .catalogcard-owner-icon {
      font-size: 20px;
      vertical-align: middle;
      margin-bottom: 5px;
      margin-right: 5px;
   }
 }
