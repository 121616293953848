.template-locked-value {
    color: #969393;
    border-bottom: 1px dashed #969393;
    cursor: not-allowed;

    .template-locked-icon {
        font-size: 18px;
    }
}

.imagetemplate-imagelist {
    display: grid;
    // grid-template-columns: auto auto auto auto;
    text-align: center;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
      }
}


.imagetemplate-image {
    width: 90%;
    height: 280px;
    margin-top: 20px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #aed4d4;
    object-fit: cover;

    @media (max-width: 900px) {
        height: 200px;
    }
    @media (max-width: 500px) {
        height: 150px;
        margin-right: 0px;
    }
}

.imagetemplate-delete {
    position: absolute;
    margin-top: 10px;
    padding: 25px;
    color: white;
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    cursor: pointer;
}