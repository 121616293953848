.profile-picture-container {
    display: flex;
    justify-content: center;
}

.profile-picture-background {
    background-color: #bab8b842;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    place-content: center;
    display: flex;
}

.profile-picture-default {
    width: 100px;
    height: 100px;
    align-self: center;
    filter: grayscale(1);
    opacity: 0.4;
    margin-bottom: 15px;
}

.profile-picture {
    align-self: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
}

.profile-username {
    text-align: center;
}

.profile-picture-actions {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #000000c4;
    border-radius: 100%;
    width: 200px;
    height: 200px;
    color: white;
    justify-content: center;
    align-items: center;
    z-index: 2;
    opacity: 0;
    transition: 1s;
    
    .profile-picture-action {
        pointer-events: none;
        margin: 15px;
        padding: 10px;
        color: white;
        background: none;
        border: none;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
    }
    
    &:hover {
        opacity: 1;
        .profile-picture-action {
            animation: button-activate 1s linear 0s forwards;
            @keyframes button-activate {
                from {
                    pointer-events: none;
                }
                to {
                    pointer-events: all;
                }
            }
        }
    }
}