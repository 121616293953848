.createcatalog {
    width: 100%;
}

.createcatalog-name {
    float: left;
    font-size: 48px;
    font-weight: 600;
    width: 100%;
}

.createcatalog-description {
    font-family: Inter;
    padding: 1%;
    float: left;
    font-size: 24px;
    min-width: 98%;
    max-width: 98%;
    min-height: 150px;
    max-height: 150px;
    margin-top: 30px;
    background-color: transparent;

    &:focus {
        outline-color: #327DA7;
    }
}

.createcatalog-template-title {
    margin-bottom: 10px;
}

.createcatalog-tips {
    text-align: left;
}

.createcatalog-info {
    display: flex;
    flex-direction: column;
}

.createcatalog-locked-field {
    text-align: left;
    color: #757575;
    border-bottom: 1px dashed;
    margin: 0px;
    cursor: not-allowed;
}

.createcatalog-locked-field-order {
    margin-bottom: 0px;
    color: #757575;
    text-align: center;
}

.createcatalog-data {
    margin-top: 60px;
    cursor: default;
}

.template-data-selection-title {
    margin-top: 50px;
}

.template-options {
    margin-top: 25px;
    margin-bottom: 50px;
    background-color: #F4FBFB;
    border: 1px solid #327DA7;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.template-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #327DA7;
    cursor: pointer;

    &:hover {
        font-weight: 700;

        .template-option-icon {
            background-color: #327DA7;
            color: #F4FBFB;
        }
    }

    .template-option-icon {
        width: 50px;
        height: 50px;
        margin: 15px;
        border: 1px solid #327DA7;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.template-error-message {
    color: red;
    font-size: 14px;
    text-align: right;
    margin-bottom: 15px;
}

.template-create-button {
    text-align: right;
    margin-bottom: 150px;

    .create-button {
        padding: 15px
    }
}



.line-input {
    border: 0px;
    background-color: transparent;
    border-bottom: 1px solid black;

    &:focus {
        outline: none;
        border-bottom: 2px solid #034E78;
    }
}