@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
.App {
  font-family: 'Inter', sans-serif;
  text-align: center;
  height: 100vh;
  background-color: #fafafa;
  // background-color: #282c34;
}

.App-body {
  min-height: 85%;
  background-color: #fafafa;
  color: #262626;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  padding-top: 100px;
  padding-left: 5%;
  padding-right: 5%;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.catalogs-list {
  display: grid;
  margin-bottom: 25px;
  // grid-template-columns: auto auto auto auto;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.breadcrumbs{
  color: #262626;
  text-align: left;
  font-size: 18px;
  opacity: .5;
  cursor: default;
  
  a {
    color: #262626;
  }

  @media (max-width: 900px) {
    font-size: 12px;
  }
}

//Pop ups
.popup-info {
  width: 26%;
  min-width: 250px;
  background-color: white;
  padding: 25px;
  text-align: left;
  border-radius: 10px;
}

.popup-action {
  float: right;
}

.popup-background {
  position: fixed;
  background-color: #00000079;
  width: 100%;
  height: 150%;
  // transform: scale(1.5, 1.5);
  transform: translate(-5%, -25%);
  z-index: 10;
}

.popup-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  place-content: center;
}


.popup-critical-action {
  background-color: #E44848 !important;
}

.popup-close-button {
  float: right;
  background-color: transparent;
  border: none;
  font-size: 22px;
  cursor: pointer;
}

.popup-message {
  text-align: right;
}


.title {
  text-align: left;
  color: #034E78;
}

.color-blue {
  color: #034E78;
}

.title-list {
  margin-left: 25px;

  @media (max-width: 900px) {
      margin-left: 15px;
  }
}

.info-tags{
  display: flex;
  align-items: center;
  margin-right: 25px;
  color: black;

  .info-tags-icon {
    height: 24px;
    padding: 5px;
    color: #27ac9e;
  }
}

.button {
  padding: 10px;
  background-color: #034e78;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  width: fit-content;
  cursor: pointer;
  float: right;
}

.primary-button {
  padding: 10px;
  background-color: #279cac;;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  border: none;
  text-decoration: none;
}

.critical-color {
  color: #E44848
}

.remove-outline {
  &:active, &:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }
}