.catalog {
    width: 100%;
    margin-bottom: 150px;
}

.catalog-header {
    display: flex;
    align-items: center;

    @media (max-width: 900px) {
        display: block;
    }
}

.catalog-name {
    width: 100%;
}

.catalog-actions-container {
    margin-left: 25px;
    display: flex;
    margin-top: 10px;
    text-align-last: right;
    justify-content: right;

    @media (max-width: 900px) {
        margin-left: 0px;
        text-align-last: left;
        justify-content: left;
        margin-bottom: 30px;
    }

    @media (max-width: 600px) {
        min-width: 182px;
    }
}

.catalog-actions {
    display: flex;
    width: fit-content;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid #034e78;
    color: #034e78;
}

.catalog-actions-item {
    cursor: pointer;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;

    &:hover {
        color: #327DA7;
    }
}

.catalog-actions-delete {
    &:hover {
        color: #E44848;
    }
}

.catalog-description {
    text-align: left;
    margin-bottom: 25px;
}

.catalog-table {
    border-collapse: collapse;
    text-align: left;
    width: 100%;
    margin-top: 25px;

    tbody {
        // border-left: 1px solid black;
        // border-right: 1px solid black;
        cursor: pointer;

        tr:hover {
            background-color: #edebeb;
        }
    }

    td, th{
        border-bottom: 1px solid black;
        padding: 15px;
    }
}

.catalog-table-container {
    display: flex;
    justify-content: center;
}

// .catalog-table-name {
//     // width: 80%;
// }

.catalog-table-date {
    // width: 30%;
    text-align: right;
}

.catalog-table-last {
    text-align: right;
}

.catalog-table-image {
    width: 75px;
    height: 50px;
    object-fit: cover;
    vertical-align: middle;
    border-radius: 5px;
    background-color: #e9e9e9;
}

.catalog-table-default {
    filter: grayscale(1);
    text-align: center;
    display: flex;
    place-content: center;
}

.catalog-table-image-default {
    width: 25px;
}