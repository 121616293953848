@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
.body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    //   sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
.code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

.form-center {
  display: flex;
  min-height: 85vh;
  align-items: center;
}

.form-container {
  background: #e8f5f5;
  font-size: 16px;
  padding: 50px;
  border-radius: 10px;
  margin-bottom: 50px;
  max-width: 450px;

  .form-title {
    margin-top: 0px;
  }
  
  .form-error {
    color: red;
  }
  
  .form-data {
    margin-top: 15px;
  }
}