.entry-catalog-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

 .entry-logo {
    height: 30vmin;
    pointer-events: none;
}

.entry-button {
  margin: 50px;
}