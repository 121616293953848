.home-body {
    width: 100%;
}

.welcome-title {
    font-size: 28px;

    @media(max-width: 600px) {
        font-size: 16px;
    }
}
